jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    "date-ch-pre": function (date) {
        date = date.trim();
        const regexDateCH = new RegExp(/^[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{4}$/);
        const regexTimeFrench = new RegExp(/^([0-9]+h)?\s*([0-9]+min)?\s*([0-9]+s)?$/);
        const regexPercent = new RegExp(/\d+(\.?\d+)?\s*%/)
        if (!date) return 0;
        found = regexDateCH.test(date)
        found2 = regexTimeFrench.test(date)
        found3 = regexPercent.test(date)
        if (regexDateCH.test(date) === true) {
            // console.log("date-ch", date);
            var eu_date = date.split(/[\.\-\/]/);
            var year = 0;
            var hour = "00:00";
            if (eu_date[2]) {
                yh = eu_date[2].split(" ");
                year = yh[0];
                if (yh[1]) hour = yh[1];
            }                /*month*/
            var month = eu_date[1];
            if (month.length == 1) {
                month = 0 + month;
            }                /*day*/
            var day = eu_date[0];
            if (day.length == 1) {
                day = 0 + day;
            } return Date.parse(year + "-" + month + "-" + day + " " + hour);
        } else if (regexTimeFrench.test(date)) {
            var timeSplited = [];
            var val = 0;
            found2 = regexTimeFrench.test(date)
            date.split(' ').map(el => {
                if (el.match(/\d+/)) {
                    // alert(el.match(/\d+/))
                    var item = parseInt(el.match(/\d+/))
                    if (el.match(/\D+/) == "h") val += item * 60 * 60
                    else if (el.match(/\D+/) == "min") val += item * 60
                    else if (el.match(/\D+/) == "s") val += item
                }
            })
            return val
        } else if (regexPercent.test(date)) {
            date = parseFloat(date.match(/\d+(\.?\d+)?/))
            return date;
        } else {
            return date;
        }
    }, "date-ch-asc": function (a, b) {
        return ((a < b) ? -1 : ((a > b) ? 1 : 0));
    }, "date-ch-desc": function (a, b) {
        return ((a < b) ? 1 : ((a > b) ? -1 : 0));
    }
});
